@base: #3c3e4a;
@whtie: #ffffff;
@hover: #ef4065;
@red: #d2284c;
@grey: #c9c9c9;
@background: #f3f3f3;
* {
    padding: 0;
    margin: 0;
}
/*选中背景颜色*/
::selection {
    background:none;
}
::-moz-selection {
    background: none;
}
/*颜色*/
html {
    color: @base;
    font-family: '微软雅黑';
    height: 100%;
}
body {
    background-color: @background;
    font-family: '微软雅黑';
    position: relative;
    overflow-x: hidden;
    height: 100%;
}
a {
    color: @base;
    cursor: pointer;
    &:hover {
        color: @hover;
        text-decoration: none;
    }
}
.white {
    color: @whtie;
}
.red {
    color: @red;
}
.bg-red {
    background-color: @red;
}
.redhov {
    color: @hover;
}
.grey {
    color: @grey;
}
/*字体大小*/
.t1 {
    font-size: 24px;
}
.t2 {
    font-size: 22px;
}
.t3 {
    font-size: 16px;
}
.t4 {
    font-size: 14px;
}
.t5 {
    font-size: 12px;
}
.t6 {
    font-size: 10px;
}
/*页面间距*/
.space {
    width: 100%;
    height: 35px;
}
.space-lg {
    width: 100%;
    height: 84px;
}
/*布局*/
.main-content {
    width: 1000px;
    margin: 0 auto;
}
.fl {
    float: left;
}
.fr {
    float: right;
}
.clear {
    clear: both;
}
.hide {
    display: none;
}
/*通顶*/
.top {
    height: 35px;
    line-height: 35px;

    // .main-content{
    //     position: relative;
    //     .sub{
    //         position: absolute;
    //         top: 34px;
    //         right: 88px;
    //         width: 88px;
    //         .top-btn{
    //             margin-bottom: -1px;
    //         }
    //     }
    // }

    .help {
        .icon {
            background: url('../img/icon/help.png') no-repeat center;
        }
    }
    .login {
        .icon {
            background: url('../img/icon/login.png') no-repeat center;
        }
    }
    .center {
        .icon {
            background: url('../img/icon/center.png') no-repeat center;
        }
    }
    .exit {
        .icon {
            background: url('../img/icon/exit.png') no-repeat center;
        }
    }
    .top-btn {
        display: inline-block;
        //width: 88px;
        padding: 0 10px;
        height: 35px;
        text-align: center;
        margin: 0;
        cursor: pointer;
        background-color: @red;
        position: relative;
        z-index: 99;
        .icon {
            width: 20px;
            height: 20px;
            line-height: 35px;
            margin-bottom: -5px;
            margin-right: 2px;
            display: inline-block;
        }
        &:hover {
            background-color: @hover;
        }
    }
}
/*导航*/
.gov-nav-bar {
    width: 100%;
    height: 65px;
    .logo{
        width: 200px;
        height: 65px;
        float: left;
        background: url('../img/logo.png') no-repeat center;
        margin-right: 50px;
    }
    .gov-nav {
        cursor: pointer;
        width: 80px;
        height: 74px;
        border-top: 1px solid @background;
        text-align: center;
        line-height: 74px;
        font-size: 16px;
        color: @red;
        float: left;
        &.hov {
            border-top: 1px solid @hover;
            color: @hover;
            background: url('../img/icon/navhov.png') no-repeat center 65px;
        }
    }
    .search-bar {
        float: right;
        width: 350px;
        border-radius: 16px;
        height: 32px;
        background: url('../img/icon/search.png') no-repeat 12px center @hover;
        margin: 22px;
        z-index: 0;
        position: relative;
        input {
            border: none;
            background: none;
            color: #fff;
            outline: none;
            margin: 6px 0 0 40px;
            width: 140px;
        }
        input::-webkit-input-placeholder {
            color: #fff;
        }
        input:-moz-placeholder {
            color: #fff;
        }
        input::-moz-placeholder {
            color: #fff;
        }
        input:-ms-input-placeholder {
            color: #fff;
        }
        a {
            color: #fff;
            margin-left: 10px;
        }
        .search-btn {
            width: 32px;
            height: 32px;
            float: right;
            background: url('../img/icon/searchbtn.png') no-repeat center;
            cursor: pointer;
        }
    }
}
.min-height{
    min-height: 735px;
}
.gov-bot {
    //position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 97px;
    background-color: @red;
    padding: 23px 0;
    p {
        margin: 0;
        font-size: 14px;
        line-height: 25px;
        color: #fff;
        text-align: center;
    }
}
/*清除ie的默认选择框样式清除，隐藏下拉箭头*/
select::-ms-expand {
    display: none;
}
/*右侧浮动*/
.float {
    width: 63px;
    height: 136px;
    background: url('../img/float.png') no-repeat center;
    position: fixed;
    top: 600px;
    right: 0;
    margin: auto;
    z-index: 1000;
    a {
        width: 63px;
        height: 68px;
        display: block;
    }
}
/*面包屑导航*/
.gov-breadcrumb {
    background-color: #fff;
    padding-left: 30px;
    height: 35px;
    line-height: 35px;
    ul {
        li {
            float: left;
            list-style: none;
            font-size: 16px;
            color: #ef4065;
        }
        span {
            float: left;
            display: inline-block;
            width: 30px;
            height: 35px;
            background: url('../img/icon/right.png') no-repeat center;
            &:last-child {
                display: none;
            }
        }
    }
}
/*弹出框*/
.modal{
    width: 409px;
    height: 386px;
    padding: 0 !important;
    margin: auto;
    background: url('../img/box.png') no-repeat center;
    .wrap{
        width: 100%;
        height: 100%;
    }
    .modalclose{
        position: absolute;
        right: 10px;
        top: 10px;
        width: 40px;
        height: 40px;
        cursor: pointer;
    }
    .title{
        width: 60%;
        margin-left: 20%;
        font-size: 24px;
        text-align: center;
        color: #fff;
        line-height: 100px;
    }
    .input-group{
        width: 289px;
        height: 41px;
        margin-left: 10px;
        line-height: 41px;
        font-size: 20px;
        margin: 0 auto 20px auto;
        border-radius: 5px;
    }
    label{
        width: 100%;
        font-size: 18px;
        color: #fff;
        font-weight: normal;
        text-align: center;
        &.modalhelp{
            background: url('../img/icon/help.png') no-repeat 100px center;
        }
        &.modaldata{
            background: url('../img/icon/data.png') no-repeat 90px center;
        }
    }
    .space{
        width: 100%;
        height: 10px;
    }
    .center{
        span{margin: 0 10px;color: #fff}
        width: 289px;
        margin: 0 auto;
        a{
            color: #fff;
        }
    }
    .button{
        width: 289px;
        height: 41px;
        line-height: 41px;
        color: #fff;
        font-size: 20px;
        margin: 15px auto 45px auto;
        background-color: #ff6e8d;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
    }
}

/*按钮*/
.gov-btn {
    width: 103px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    float: left;
    margin-right: 40px;
    margin-top: 20px;
    cursor: pointer;
    background: url('../img/btn.png') no-repeat center;
    &.dis {
        background: url('../img/btn-dis.png') no-repeat center;
    }
    &.sm{
        width: 50px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        margin: 0 10px 0 0;
        background: url('../img/btn-sm.png') no-repeat center;
    }
    &.lg{
        width: 275px;
        background: url('../img/btn-lg.png') repeat center;
    }
}
/*分页*/
.page{
    p{
        font-size: 12px;
        color: #666666;
        span{
            float: right;
            margin: 7px;
            cursor: pointer;
            &.go{
                color: #fff;
                background: #ff93aa;
                border-radius: 4px;
                padding: 3px 5px;
                margin-top: 3px;
            }
        }
        input{
            float: right;
            margin-top: 6px;
            width: 20px;
        }
    }
}
